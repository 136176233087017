import soskincsemPic from "../../assets/soskincsem_devices_1000x625.jpg"
import coverglassPic from "../../assets/coverglass_devices_1000x625.jpg"
import balintanalitikaPic from "../../assets/balintanalitika_devices_1000x625.jpg"
import projectCTAPic from "../../assets/project_cta_picture_1000x625.jpg"

export const projectItems = [
  {
    id: 1,
    title: "project1_title",
    description: "project1_description",
    image: coverglassPic,
    imageAlt: "project1_imageAlt",
    url: "https://www.coverglass.hu/",
  },
  {
    id: 2,
    title: "project2_title",
    description: "project2_description",
    image: balintanalitikaPic,
    imageAlt: "project2_imageAlt",
    url: "https://www.balintanalitika.hu/",
  },
  {
    id: 3,
    title: "project3_title",
    description: "project3_description",
    image: soskincsemPic,
    imageAlt: "project3_imageAlt",
    url: "https://www.soskincsem.hu/",
  },
  {
    id: 4,
    title: "project4_title",
    description: "project4_description",
    image: projectCTAPic,
    imageAlt: "project4_imageAlt",
    url: "",
  }
];
