import React from "react";
import "../../styles/main.scss";
import "./AboutMe.scss";
import { useTranslation } from "react-i18next";

const AboutMe = () => {
  const { t } = useTranslation("AboutMe");

  return (
    <section className="section__about-me container" id="about">
      <h3 className="section__title">{t(`about_title`)}</h3>
      <div className="section__content">
        <div className="section__content__text">
          <p>{t("about_me")}</p>
          <p>{t("junior_developer")}</p>
          <p>{t("freelance_developer")}</p>

          <p>{t("outside_work")}</p>
          <p>{t("cv")}</p>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
