import React from "react";
import "./Bar.scss";

const Bar = ({ showNav, toggleNav }) => {
  return (
    <>
      <input
        type="checkbox"
        id="menu-checkbox"
        role="button"
        aria-label="Display the menu"
        className="menu"
        checked={showNav}
        onChange={toggleNav}
      />
      <label htmlFor="menu-checkbox" className="bar-icon"></label>
    </>
  );
};

export default Bar;