import React, { useContext, useState, useEffect, useRef } from "react";
import { ThemeContext } from "../../Theme";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../styles/main.scss";
import "./NavigationBar.scss";
import Bar from "./Bars";
import logo from "../../assets/logo-520x520.png";

const NavigationBar = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const { i18n } = useTranslation();
  const { t } = useTranslation("NavigationBar");
  const [showNav, setShowNav] = useState(false);
  const prevScrollY = useRef(0);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && showNav) {
        setShowNav(false);
      }
      prevScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [showNav]);

  return (
    <div className={`nav-bar ${showNav ? "show-nav" : ""}`}>
      <div className="nav-bar__left">
        <div className="nav-bar__logo">
          <RouterLink to="/">
            <img src={logo} alt="logo" />
          </RouterLink>
          <h1 className="nav-bar__logo__text">andras.dev</h1>
        </div>
      </div>
      <div className="menu-icon" onClick={toggleNav}>
        <Bar showNav={showNav} toggleNav={toggleNav} />
      </div>
      <div className={`nav-bar__right ${showNav ? "show" : ""}`}>
        <div className="nav-bar__links">
          <ul>
            <li>
              <Link to="projects" smooth={true} duration={100} onClick={toggleNav}>
                {t("Projects")}
              </Link>
            </li>
            <li>
              <Link to="skills" smooth={true} duration={100} onClick={toggleNav}>
                {t("Skills")}
              </Link>
            </li>
            <li>
              <Link to="about" smooth={true} duration={100}onClick={toggleNav}>
                {t("About")}
              </Link>
            </li>
            <li>
              <Link to="contact" smooth={true} duration={100} onClick={toggleNav}>
                {t("Contact")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="nav-bar__extra">
          <ul>
          <li style={{color: "#333", cursor: 'pointer'}} onClick={() => changeLanguage(i18n.language === 'en' ? 'hu' : 'en')}>
              {i18n.language === 'en' ? 'HU' : 'EN'}
             </li>
            <li>
              <DarkModeSwitch
                checked={theme === "light"}
                onChange={toggleTheme}
                size={30}
                moonColor="#FFFF00"
                sunColor="#FDB813"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
