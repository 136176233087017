import React from "react";
import { Link } from "react-scroll";
import "../../styles/main.scss";
import "./Contact.scss";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const currentYear = new Date().getFullYear();
  const { t, i18n } = useTranslation("Contact");
  const pdfLink =
    i18n.language === "hu"
      ? "assets/andras_nemeth_cv_hun.pdf"
      : "assets/andras_nemeth_cv_eng.pdf";

  return (
    <div className="outer-div">
      <section className="section__contact container" id="contact">
        <div className="section__contact__content">
          <div className="section__contact__content__refs">
            <ul>
              <li>
                <a href="/">{t(`links.Home`)}</a>
              </li>
              <li>
                <Link to="projects" smooth={true} duration={100}>
                  {t(`links.Projects`)}
                </Link>
              </li>
              <li>
                <Link to="skills" smooth={true} duration={100}>
                  {t(`links.Skills`)}
                </Link>
              </li>
              <li>
                <Link to="about" smooth={true} duration={100}>
                  {t(`links.About`)}
                </Link>
              </li>
            </ul>
          </div>
          <div className="section__contact__content__socials">
            <ul>
              <li>
                <a href="https://www.linkedin.com/in/andrasnemeth939/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#333"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ width: "100%", height: "100%" }}
                    className="svg-icon"
                  >
                    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                    <rect x="2" y="9" width="4" height="12"></rect>
                    <circle cx="4" cy="4" r="2"></circle>
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://github.com/andrisnemeth">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    style={{ width: "100%", height: "100%" }}
                    className="svg-icon"
                  >
                    <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <div className="section__contact__content__cta">
            <p className="section__contact__content__cta__text">
              {t(`interested`)}
            </p>
            <div className="section__contact__content__cta__buttons">
              <a href="mailto:info@andrasdev.hu" className="btn btn--primary">
                {t(`buttons.contact_me`)}
              </a>
              <a
                href={pdfLink}
                className="btn btn--secondary"
                target="_blank"
                rel="noreferrer"
              >
                {t(`buttons.download_cv`)}
              </a>
            </div>
          </div>
          <div className="section__contact__content__copy">
            <p>{t(`copy.copy1`, { year: currentYear })}</p>
            <p>{t(`copy.copy2`)}</p>
          </div>
          {/* <div className="section__contact__content__legal">
          <p>Privacy Policy</p>
          <p>Terms of Use</p>
        </div> */}
        </div>
      </section>
    </div>
  );
};

export default Contact;
