import React from "react";
import "../../styles/main.scss";
import "./Skills.scss";
// import { skillItems } from "./skillItems";
import { useTranslation } from "react-i18next";

const Skills = () => {
  const { t } = useTranslation("Skills");
  const skills = t("skills", { returnObjects: true });

  return (
    <section className="section__skills container" id="skills">
      <h3 className="section__title">{t("skills_title")}</h3>
      <div className="section__skills__content">
      {skills.map((item, index) => (
          <div className="section__skills__content__item" key={index}>
            <div className="section__skills__content__item__header">
              <p className="section__skills__content__item__header__title">
                {item.name}
              </p>
            </div>
            <div className="section__skills__content__item__body">
              <ul className="section__skills__content__item__body__list">
                {item.content.map((content, index) => (
                  <li
                    className="section__skills__content__item__body__list__item"
                    key={index}
                  >
                    {content}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Skills;
