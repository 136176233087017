import React from "react";
import "../../styles/main.scss";
import "./Projects.scss";
// import { Link } from "react-router-dom";
import { projectItems } from "./projectItems";
import { useTranslation } from "react-i18next";

const validateUrl = (url) => {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlRegex.test(url);
};
const Projects = () => {
  const { t } = useTranslation("Projects");

  return (
    <section className="section__projects container" id="projects">
      <h3 className="section__title">{t("projects_title")}</h3>
      <div className="section__projects__content">
        {projectItems.map((item, index) => (
          <div className="section__projects__content__item" key={index}>
            <div className="section__projects__content__item__img">
              <div className="section__projects__content__item__img">
                {" "}
                <img
                  src={item.image}
                  alt={t(`projects.project${item.id}.imageAlt`)}
                />
              </div>
            </div>
            <div className="section__projects__content__item__text">
              <div className="section__projects__content__item__text__body">
                <h4 className="section__projects__content__item__text__body__title">
                  {t(`projects.project${item.id}.title`)}
                </h4>
                <p className="section__projects__content__item__text__body__description">
                  {t(`projects.project${item.id}.description`)}
                </p>
              </div>
              <div className="section__projects__content__item__text__footer">
                {t(item.title) !== t("project4_title") ? (
                  <>
                    <a
                      href={validateUrl(item.url) ? item.url : "#"}
                      className="section__projects__content__item__text__footer__link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("buttons.visit_website")}
                    </a>
                    {/* <Link
                      to={item.link}
                      className="section__projects__content__item__text__footer__link"
                    >
                      {t("buttons.read_more")}
                    </Link> */}
                  </>
                ) : (
                  <a
                    href="mailto:andras_nemeth@outlook.com"
                    className="section__projects__content__item__text__footer__link projects__cta__btn"
                  >
                    {t("buttons.get_in_touch")}
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Projects;
