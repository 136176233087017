import React, { useContext } from "react";
import { ThemeContext } from "../../Theme";
import "./Hero.scss";
import "../../styles/main.scss";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import human from "../../assets/Me-1920x1055.png";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const screenWidth = useScreenWidth();
  const { theme } = useContext(ThemeContext);
  const fillColor = theme === "light" ? "#fff" : "#181818";
  const { t } = useTranslation("Hero");

  return (
    <>
      <div className="hero-flex-layout">
        <div className="hero__text">
          <h2 className="hero__title__intro">{t("intro")}</h2>
          <h1 className="hero__title__main">{t("main")}</h1>
          <h1 className="hero__title__intro">{t("description")}</h1>
          <div className="hero_cta">
            <a
              href="mailto:info@andrasdev.hu"
              className="hero__cta__btn"
            >
              {t("get_in_touch")}
            </a>
          </div>
        </div>
        <div className="hero__image">
          {screenWidth < 480 ? (
            <img src={human} alt="hero" className="hero__image_img" />
          ) : (
            <img src={human} alt="hero" className="hero__image_img" />
          )}
        </div>
      </div>
      <div className="hero__wave">
        <svg
          preserveAspectRatio="none"
          width="1920"
          height="74"
          viewBox="0 0 1440 74"
          className="hero__wave__svg"
        >
          {screenWidth < 1024 ? (
            <path
              fill={fillColor}
              d="M456.464 0.0433865C277.158 -1.70575 0 50.0141 0 50.0141V74H1440V50.0141C1440 50.0141 1320.4 31.1925 1243.09 27.0276C1099.33 19.2816 1019.08 53.1981 875.138 50.0141C710.527 46.3727 621.108 1.64949 456.464 0.0433865Z"
              style={{ transform: "scaleY(0.8)", scaleX: "1.2" }}
            ></path>
          ) : (
            <path
              fill={fillColor}
              d="M456.464 0.0433865C277.158 -1.70575 0 50.0141 0 50.0141V74H1440V50.0141C1440 50.0141 1320.4 31.1925 1243.09 27.0276C1099.33 19.2816 1019.08 53.1981 875.138 50.0141C710.527 46.3727 621.108 1.64949 456.464 0.0433865Z"
            ></path>
          )}
        </svg>
      </div>
    </>
  );
};

export default Hero;
