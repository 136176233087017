import React, { useContext, useEffect } from "react";
import { ThemeContext } from "./Theme";
import { Routes, Route } from "react-router-dom";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import Hero from "./components/Hero/Hero";
import Projects from "./components/Projects/Projects";
import Skills from "./components/Skills/Skills";
import AboutMe from "./components/AboutMe/AboutMe";
import Contact from "./components/Contact/Contact";
import Divider from "./components/Divider/Divider";

function App() {
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    document.body.classList.remove("light");
    document.body.classList.remove("dark");

    document.body.classList.add(theme);
  }, [theme]);

  return (
    <div className={`${theme}-theme`}>
      <NavigationBar />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero />
              <Projects />
              <Skills />
              <div style={{ position: "relative" }}>
                <AboutMe />
                <Divider />
              </div>
              <Contact />
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
